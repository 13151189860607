import { useMutation, useQuery } from "react-query";
import { env } from "../env";
import { getAuthHeader } from "../utils/tokenUtils";
import { ConversionFactorToCreate } from "../features/conversionFactor/conversionFactorSlice";
import toast from "react-hot-toast";

export type ConversionFactor = {
  id: number;
  emissionsSourceId: number | undefined;
  unitOfMeasureId: number | undefined;
  recycledConversionFactor: number | undefined;
  nonRecycledConversionFactor: number | undefined;
  emissionsSourceName: string;
  emissionsCategoryName: string;
  emissionsTypeName: string;
};

export const useConversionFactor = () => {
  const fetchConversionFactors = async () => {
    const url = `${env.REACT_APP_API_BASE_URL}/conversionFactor`;

    const response = await fetch(url, {
      headers: { Authorization: getAuthHeader() },
    });

    const conversionFactorsResponse = (await response.json()) as ConversionFactor[];
    return conversionFactorsResponse;
  };

  const { data, isLoading, refetch } = useQuery("conversionFactors", fetchConversionFactors);

  const createConversionFactorMutation = useMutation(
    async (conversionFactor: ConversionFactorToCreate) => {
      const url = `${env.REACT_APP_API_BASE_URL}/conversionFactor`;
      const response = await fetch(url, {
        method: "POST",
        headers: { Authorization: getAuthHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(conversionFactor),
      });

      return response.ok;
    },
    {
      onSuccess: (response: boolean) => {
        if (response) {
          toast.success(`ConversionFactor created`);
          refetch();
        } else {
          toast.error("ConversionFactor not created");
        }
      },
      onError: () => {
        toast.error("Error");
      },
    }
  );

  const createConversionFactor = async (conversionFactor: ConversionFactorToCreate) =>
    await createConversionFactorMutation.mutateAsync(conversionFactor);

  const deleteConversionFactorMutation = useMutation(
    async (id: number) => {
      const url = `${env.REACT_APP_API_BASE_URL}/conversionFactor/${id}`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: { Authorization: getAuthHeader() },
      });

      return response.ok;
    },
    {
      onSuccess: (response: boolean) => {
        if (response) {
          toast.success(`ConversionFactor deleted`);
          refetch();
        } else {
          toast.error("ConversionFactor not deleted");
        }
      },
      onError: () => {
        toast.error("Error");
      },
    }
  );

  const deleteConversionFactor = async (id: number) => {
    if (window.confirm("Are you sure you want to delete?"))
      await deleteConversionFactorMutation.mutateAsync(id);
  };

  return { data, isLoading, createConversionFactor, deleteConversionFactor };
};
