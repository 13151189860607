import { useNavigate, useParams } from "react-router-dom";
import {
  Material,
  selectMaterialUsage,
  setStatus,
} from "../features/materialUsage/materialUsageSlice";
import { useFormControl } from "./useFormControl";
import { useSave } from "./useSave";
import { useCalculate } from "./useCalculate";
import { FormStatus } from "../models/formStatus";
import { useDispatch, useSelector } from "react-redux";

type EmissionsEntryDto = {
  highLevelDetailsId: number;
  emissionsSourceId: number;
  quantity: number;
  unitOfMeasureId: number;
  percentageRecycled: number | undefined;
  scopeId: number;
};

type SaveEmissionsEntryRequest = {
  emissionsEntries: EmissionsEntryDto[];
};

const createSavePayload = (materials: Material[], workId: string): SaveEmissionsEntryRequest => {
  return {
    emissionsEntries: materials.map((m) => ({
      highLevelDetailsId: parseInt(workId),
      emissionsSourceId: m.materialSubtypeId || 0,
      quantity: parseInt(m.quantity || ""),
      unitOfMeasureId: m.unitOfMeasureId ?? 0,
      percentageRecycled: parseFloat(m.percentageRecycled || ""),
      scopeId: parseInt(m.scopeId || ""),
    })),
  };
};

export const useMaterialEmissionsEntry = () => {
  const materialUsage = useSelector(selectMaterialUsage);
  const { closeEntry } = useFormControl();
  const { save, isSaving } = useSave<SaveEmissionsEntryRequest>();
  const navigate = useNavigate();
  const { mode, workId } = useParams();
  const { calculateResults } = useCalculate();
  const dispatch = useDispatch();

  const back = () => {
    navigate(`/${mode}/${workId}/energy-usage`);
  };

  const saveAndClose = async () => {
    if (!workId) return;

    const { success } = await save(
      createSavePayload(materialUsage.form, workId),
      "/emissionsEntry/save/emissionscategory/3"
    );

    await calculateResults(workId);

    if (success) {
      closeEntry();
    }
  };

  const next = async () => {
    if (!workId) return;

    const { success } = await save(
      createSavePayload(materialUsage.form, workId),
      "/emissionsEntry/save/emissionscategory/3"
    );

    if (success) {
      navigate(`/${mode}/${workId}/asset-usage`);
      dispatch(setStatus(FormStatus.Complete));
    }
  };

  return {
    back,
    saveAndClose,
    next,
    isSaving,
  };
};
