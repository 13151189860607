import { Waste } from "../../../features/waste/wasteSlice";
import { useEmissionsSourceLookup } from "../../../hooks/useEmissionsSource";
import { useScope } from "../../../hooks/useScope";
import { useUnitOfMeasureLookup } from "../../../hooks/useUnitOfMeasure";
import { Category } from "../../../utils/consts";
import { DeleteButton } from "../../DeleteButton/DeleteButton";
import { NumberInput, Select } from "../../FormComponents";
import "./WasteRow.scss";

interface WasteRowProps {
  waste: Waste;
  onRemove: (a: number | null) => void;
  onChange: (a: Waste) => void;
  disabled?: boolean;
}

export const WasteRow = ({ waste, onRemove, onChange, disabled }: WasteRowProps) => {
  const { id, wasteMaterialId, quantity, unitOfMeasureId, percentageRecycled, scopeId } = waste;

  const { values: wasteMaterials } = useEmissionsSourceLookup(Category.WASTE);
  const { values: scopes } = useScope();
  const { values: units } = useUnitOfMeasureLookup(wasteMaterialId ?? 0);

  const handleWasteMaterialChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({ ...waste, wasteMaterialId: Number(e.currentTarget.value) });
  };

  const handleQuantityChange = (quantity: string) => {
    return onChange({ ...waste, quantity: quantity });
  };

  const handleUnitofMeasureChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({ ...waste, unitOfMeasureId: Number(e.currentTarget.value) });
  };

  const handlePercentageRecycledChange = (percentageRecycled: string) => {
    return onChange({ ...waste, percentageRecycled: percentageRecycled });
  };

  const handleScopeChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({ ...waste, scopeId: e.currentTarget.value });
  };

  return (
    <div className="waste-row">
      <Select
        id={`waste-material-${id}`}
        label="Waste Material"
        options={wasteMaterials}
        value={wasteMaterialId}
        onChange={handleWasteMaterialChange}
        disabled={disabled}
      />
      <NumberInput
        id={`quantity-${id}`}
        label="Quantity"
        value={quantity}
        min={0}
        onChange={handleQuantityChange}
        disabled={disabled}
      />
      <Select
        id={`units-${id}`}
        label="Units"
        options={units}
        value={unitOfMeasureId}
        onChange={handleUnitofMeasureChange}
        disabled={disabled}
      />
      <NumberInput
        id={`percentage-recycled-${id}`}
        label="Recycled"
        value={percentageRecycled}
        min={0}
        max={100}
        onChange={handlePercentageRecycledChange}
        disabled={disabled}
        percentage={true}
      />
      <Select
        id={`scope-${id}`}
        label="Scope"
        options={scopes}
        value={scopeId}
        onChange={handleScopeChange}
        disabled={disabled}
      />

      <DeleteButton onClick={() => onRemove(wasteMaterialId)} disabled={disabled} />
    </div>
  );
};
