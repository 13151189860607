import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

export type ConversionFactorToCreate = {
  emissionsSourceId: number;
  unitOfMeasureId: number;
  recycledConversionFactor: number;
  nonRecycledConversionFactor: number;
};

interface ConversionFactorState {
  conversionFactor: ConversionFactorToCreate;
}

const initialState: ConversionFactorState = {
  conversionFactor: {
    emissionsSourceId: 0,
    unitOfMeasureId: 0,
    recycledConversionFactor: 0,
    nonRecycledConversionFactor: 0,
  },
};

export const conversionFactorSlice = createSlice({
  name: "conversionFactor",
  initialState,
  reducers: {
    setEmissionsSource: (state, action: PayloadAction<number>) => {
      state.conversionFactor.emissionsSourceId = action.payload;
    },
    setUnits: (state, action: PayloadAction<number>) => {
      state.conversionFactor.unitOfMeasureId = action.payload;
    },
    setRecycledConversionFactor: (state, action: PayloadAction<number>) => {
      state.conversionFactor.recycledConversionFactor = action.payload;
    },
    setNonRecycledConversionFactor: (state, action: PayloadAction<number>) => {
      state.conversionFactor.nonRecycledConversionFactor = action.payload;
    },
    resetConversionFactorFormState: (state) => {
      state.conversionFactor = initialState.conversionFactor;
    },
  },
});

export const {
  setEmissionsSource,
  setUnits,
  setRecycledConversionFactor,
  setNonRecycledConversionFactor,
  resetConversionFactorFormState,
} = conversionFactorSlice.actions;

export const conversionFactorForm = (state: RootState) => state.conversionFactorForm;
