import Header from "../Header/Header";
import "./AssetHeaders.scss";

const AssetHeaders = () => {
  return (
    <div className="asset-headers">
      <Header label="Asset type" />
      <Header label="Asset" />
      <Header label="Quantity" />
      <Header label="Units" />
      <Header label="Percentage obtained from recycled sources" />
      <Header label="Scope" />
    </div>
  );
};

export default AssetHeaders;
