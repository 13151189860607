import { useDispatch, useSelector } from "react-redux";
import { Card } from "../Card/Card";
import { TextInput } from "../FormComponents";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import Table, { RowData } from "../Table/Table";
import {
  resetUnitOfMeasureFormState,
  setUnitOfMeasureName,
  unitOfMeasureForm,
} from "../../features/unitOfMeasure/unitOfMeasureSlice";
import { useUnitOfMeasure } from "../../hooks/useUnitOfMeasure";

const Units = () => {
  const { values: unitOfMeasures, createUnitOfMeasure, deleteUnitOfMeasure } = useUnitOfMeasure();

  const dispatch = useDispatch();

  const { unitOfMeasure } = useSelector(unitOfMeasureForm);

  const unitOfMeasuresRowData: RowData[] = [];
  unitOfMeasures?.forEach((unitOfMeasure) => {
    unitOfMeasuresRowData.push({
      ...unitOfMeasure,
      actions: (
        <div className="actions">
          <PrimaryButton
            aria-label="Delete UnitOfMeasure"
            label="Delete"
            onClick={() => deleteUnitOfMeasure(unitOfMeasure.id)}
          />
        </div>
      ),
    });
  });

  unitOfMeasuresRowData.push({
    name: (
      <TextInput
        id={"unitOfMeasure-name"}
        label={"Name"}
        value={unitOfMeasure.name}
        onChange={(e) => dispatch(setUnitOfMeasureName(e.currentTarget.value))}
      ></TextInput>
    ),
    actions: (
      <div className="actions">
        <PrimaryButton
          label={"Add Units"}
          onClick={async () => {
            if (await createUnitOfMeasure(unitOfMeasure)) {
              dispatch(resetUnitOfMeasureFormState());
            }
          }}
        />
      </div>
    ),
  });

  return (
    <div className="card-layout">
      <Card title="units">
        <Table
          columns={[
            {
              key: "name",
              header: "Name",
            },
            {
              key: "actions",
              header: "Actions",
            },
          ]}
          data={unitOfMeasuresRowData}
        />
      </Card>
    </div>
  );
};

export default Units;
