import { useDispatch, useSelector } from "react-redux";
import { Card } from "../Card/Card";
import { NumberInput, Select, TextInput } from "../FormComponents";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import Table, { RowData } from "../Table/Table";
import {
  resetConversionFactorFormState,
  conversionFactorForm,
  setEmissionsSource,
  setRecycledConversionFactor,
  setNonRecycledConversionFactor,
  setUnits,
} from "../../features/conversionFactor/conversionFactorSlice";
import { useConversionFactor } from "../../hooks/useConversionFactor";
import { useLookup } from "../../hooks/useLookup";
import { LookupKey } from "../../models/lookup";
import { useEmissionsTypeLookup } from "../../hooks/useEmissionsType";
import { useEmissionsSourceLookup } from "../../hooks/useEmissionsSource";
import { useState } from "react";
import { useUnitOfMeasure } from "../../hooks/useUnitOfMeasure";
import { Category } from "../../utils/consts";

const ConversionFactor = () => {
  const {
    data: conversionFactors,
    createConversionFactor,
    deleteConversionFactor,
  } = useConversionFactor();

  const dispatch = useDispatch();

  const [categoryId, setCategoryId] = useState<number>(0);
  const [typeId, setTypeId] = useState<number>(0);

  const { conversionFactor } = useSelector(conversionFactorForm);
  const { values: emissionsCategories } = useLookup(LookupKey.Category);
  const { values: emissionsTypes } = useEmissionsTypeLookup(categoryId);
  const { values: unitOfMeasures } = useUnitOfMeasure();
  const { values: emissionsSourcesLookups } = useEmissionsSourceLookup(categoryId, typeId);

  const conversionFactorsRowData: RowData[] = [];
  conversionFactors?.forEach((conversionFactor) => {
    conversionFactorsRowData.push({
      ...conversionFactor,
      source: conversionFactor.emissionsSourceName,
      category: conversionFactor.emissionsCategoryName,
      type: conversionFactor.emissionsTypeName ?? "N/A",
      units: unitOfMeasures?.find((u) => u.id === conversionFactor.unitOfMeasureId)?.name,
      actions: (
        <div className="actions">
          <PrimaryButton
            aria-label="Delete ConversionFactor"
            label="Delete"
            onClick={() => deleteConversionFactor(conversionFactor.id)}
          />
        </div>
      ),
    });
  });

  conversionFactorsRowData.push({
    category: (
      <Select
        id="EmissionsCategory"
        label="Emissions Category"
        value={categoryId}
        options={emissionsCategories}
        onChange={(e) => setCategoryId(Number(e.currentTarget.value))}
      />
    ),
    type: (
      <Select
        id="Emissions Type"
        label="EmissionsType"
        value={typeId}
        options={emissionsTypes}
        disabled={categoryId === Category.WASTE} // Waste has no type
        onChange={(e) => setTypeId(Number(e.currentTarget.value))}
      />
    ),
    source: (
      <Select
        id="Source"
        label="Source"
        value={conversionFactor.emissionsSourceId ?? ""}
        options={emissionsSourcesLookups}
        onChange={(e) => dispatch(setEmissionsSource(Number(e.currentTarget.value)))}
      />
    ),
    units: (
      <Select
        id="Units"
        label="Units"
        value={conversionFactor.unitOfMeasureId ?? ""}
        options={unitOfMeasures ?? []}
        onChange={(e) => dispatch(setUnits(Number(e.currentTarget.value)))}
      />
    ),
    recycledConversionFactor: (
      <NumberInput
        id={"conversionFactor-recycled"}
        label={"Recycled"}
        value={conversionFactor.recycledConversionFactor?.toString() ?? "0"}
        onChange={(e) => dispatch(setRecycledConversionFactor(Number(e)))}
      />
    ),
    nonRecycledConversionFactor: (
      <NumberInput
        id={"conversionFactor-non-recycled"}
        label={"Non-Recycled"}
        value={conversionFactor.nonRecycledConversionFactor?.toString() ?? "0"}
        onChange={(e) => dispatch(setNonRecycledConversionFactor(Number(e)))}
      />
    ),
    actions: (
      <div className="actions">
        <PrimaryButton
          label={"Add Conversion Factor"}
          onClick={async () => {
            if (await createConversionFactor(conversionFactor)) {
              dispatch(resetConversionFactorFormState());
            }
          }}
        />
      </div>
    ),
  });

  return (
    <div className="card-layout">
      <Card title="conversion factors">
        <Table
          columns={[
            {
              key: "category",
              header: "Category",
            },
            {
              key: "type",
              header: "Type",
            },
            {
              key: "source",
              header: "Source",
            },
            {
              key: "units",
              header: "Units",
            },
            {
              key: "recycledConversionFactor",
              header: "Recycled Conversion Factor",
            },
            {
              key: "nonRecycledConversionFactor",
              header: "Non-Recycled Conversion Factor",
            },
            {
              key: "actions",
              header: "Actions",
            },
          ]}
          data={conversionFactorsRowData}
        />
      </Card>
    </div>
  );
};

export default ConversionFactor;
