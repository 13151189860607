import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

export type EmissionsSourceToCreate = {
  name: string;
  emissionsCategoryId: number | undefined;
  emissionsTypeId: number | null | undefined;
};

interface EmissionsSourceState {
  emissionsSource: EmissionsSourceToCreate;
}

const initialState: EmissionsSourceState = {
  emissionsSource: {
    name: "",
    emissionsCategoryId: undefined,
    emissionsTypeId: undefined,
  },
};

export const emissionsSourceSlice = createSlice({
  name: "emissionsSource",
  initialState,
  reducers: {
    setEmissionsSourceName: (state, action: PayloadAction<string>) => {
      state.emissionsSource.name = action.payload;
    },
    setEmissionsSourceCategory: (state, action: PayloadAction<number>) => {
      state.emissionsSource.emissionsCategoryId = action.payload;
    },
    setEmissionsSourceType: (state, action: PayloadAction<number>) => {
      state.emissionsSource.emissionsTypeId = action.payload;
    },
    resetEmissionsSourceFormState: (state) => {
      state.emissionsSource = initialState.emissionsSource;
    },
  },
});

export const {
  setEmissionsSourceName,
  setEmissionsSourceCategory,
  setEmissionsSourceType,
  resetEmissionsSourceFormState,
} = emissionsSourceSlice.actions;

export const emissionsSourceForm = (state: RootState) => state.emissionsSourceForm;
