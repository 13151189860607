import { PageContainer } from "../../components/PageContainer/PageContainer";

import ConversionFactor from "../../components/Admin/ConversionFactor";
import EmissionsSource from "../../components/Admin/EmissionsSource";
import EmissionsType from "../../components/Admin/EmissionsType";
import Units from "../../components/Admin/Units";

const EmissionsEntryManagement = () => {
  return (
    <PageContainer heading="Emissions Entry Management">
      <ConversionFactor />
      <EmissionsType />
      <EmissionsSource />
      <Units />
    </PageContainer>
  );
};

export default EmissionsEntryManagement;
