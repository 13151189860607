import { useMutation, useQuery } from "react-query";
import { env } from "../env";
import { getAuthHeader } from "../utils/tokenUtils";
import { Category } from "../utils/consts";
import { EmissionsTypeToCreate } from "../features/emissionsType/emissionsTypeSlice";
import toast from "react-hot-toast";

export type EmissionsType = {
  id: number;
  name: string;
};

export const useEmissionsTypeLookup = (emissionsCategoryId: Category) => {
  const fetchLookupValues = async () => {
    const url = `${env.REACT_APP_API_BASE_URL}/emissionsType/emissionsCategory/${emissionsCategoryId}`;
    const response = await fetch(url, {
      headers: { Authorization: getAuthHeader() },
    });
    const emissionsTypeResponse: EmissionsType[] = await response.json();

    return emissionsTypeResponse;
  };

  const { data, isLoading } = useQuery(
    `emissionsCategory-${emissionsCategoryId}`,
    fetchLookupValues
  );

  return { values: data || [], isLoading };
};

export const useEmissionsType = () => {
  const fetchEmissionsTypes = async () => {
    const url = `${env.REACT_APP_API_BASE_URL}/emissionsType`;

    const response = await fetch(url, {
      headers: { Authorization: getAuthHeader() },
    });

    const emissionsTypesResponse = (await response.json()) as EmissionsType[];
    return emissionsTypesResponse;
  };

  const { data, isLoading, refetch } = useQuery("emissionsTypes", fetchEmissionsTypes);

  const createEmissionsTypeMutation = useMutation(
    async (emissionsType: EmissionsTypeToCreate) => {
      const url = `${env.REACT_APP_API_BASE_URL}/emissionsType`;
      const response = await fetch(url, {
        method: "POST",
        headers: { Authorization: getAuthHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(emissionsType),
      });

      return response.ok;
    },
    {
      onSuccess: (response: boolean) => {
        if (response) {
          toast.success(`EmissionsType created`);
          refetch();
        } else {
          toast.error("EmissionsType not created");
        }
      },
      onError: () => {
        toast.error("Error");
      },
    }
  );

  const createEmissionsType = async (emissionsType: EmissionsTypeToCreate) =>
    await createEmissionsTypeMutation.mutateAsync(emissionsType);

  const deleteEmissionsTypeMutation = useMutation(
    async (id: number) => {
      const url = `${env.REACT_APP_API_BASE_URL}/emissionsType/${id}`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: { Authorization: getAuthHeader() },
      });

      return response.ok;
    },
    {
      onSuccess: (response: boolean) => {
        if (response) {
          toast.success(`EmissionsType deleted`);
          refetch();
        } else {
          toast.error("EmissionsType not deleted");
        }
      },
      onError: () => {
        toast.error("Error");
      },
    }
  );

  const deleteEmissionsType = async (id: number) => {
    if (window.confirm("Are you sure you want to delete?"))
      await deleteEmissionsTypeMutation.mutateAsync(id);
  };

  return { data, isLoading, createEmissionsType, deleteEmissionsType };
};
