import { PageContainer } from "../../components/PageContainer/PageContainer";

import "./Admin.scss";

import { NavButton } from "../../components/NavButton/NavButton";
import { Card } from "../../components/Card/Card";

const Admin = () => {
  return (
    <PageContainer heading="Admin Portal">
      <div className="card-layout">
        <Card title="Manage Emissions Entry Data">
          Manage Emissions Types, Emissions Sources, Units and Conversion Factors here
          <NavButton label="Go" href="/admin/emissionsentrymanagement" />
        </Card>
        <Card title="Manage Users and Organisations">
          Manage Users and Organisations here
          <br />
          <NavButton label="Go" href="/admin/userorg" />
        </Card>
      </div>
    </PageContainer>
  );
};

export default Admin;
