import { configureStore } from "@reduxjs/toolkit";
import { formControlSlice } from "../features/formControl/formControlSlice";
import { basicDetailsSlice } from "../features/basicDetails/basicDetailsSlice";
import { assetUsageSlice } from "../features/assetUsage/assetUsageSlice";
import { energyUsageSlice } from "../features/energyUsage/energyUsageSlice";
import { materialUsageSlice } from "../features/materialUsage/materialUsageSlice";
import { wasteSlice } from "../features/waste/wasteSlice";
import { userSlice } from "../features/user/userSlice";
import { sectorResultFormSlice } from "../features/sectorResultsSlice/sectorResultsSlice";
import { organisationSlice } from "../features/organisation/organisationSlice";
import { managedUserSlice } from "../features/managedUser/managedUserSlice";
import { unitOfMeasureSlice } from "../features/unitOfMeasure/unitOfMeasureSlice";
import { emissionsTypeSlice } from "../features/emissionsType/emissionsTypeSlice";
import { emissionsSourceSlice } from "../features/emissionsSource/emissionsSourceSlice";
import { conversionFactorSlice } from "../features/conversionFactor/conversionFactorSlice";

export const store = configureStore({
  reducer: {
    formControl: formControlSlice.reducer,
    basicDetails: basicDetailsSlice.reducer,
    assetUsage: assetUsageSlice.reducer,
    energyUsage: energyUsageSlice.reducer,
    materialUsage: materialUsageSlice.reducer,
    waste: wasteSlice.reducer,
    user: userSlice.reducer,
    sectorResultsForm: sectorResultFormSlice.reducer,
    organisationForm: organisationSlice.reducer,
    unitOfMeasureForm: unitOfMeasureSlice.reducer,
    managedUserForm: managedUserSlice.reducer,
    emissionsTypeForm: emissionsTypeSlice.reducer,
    emissionsSourceForm: emissionsSourceSlice.reducer,
    conversionFactorForm: conversionFactorSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
