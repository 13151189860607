import { useMutation, useQuery } from "react-query";
import { env } from "../env";
import { getAuthHeader } from "../utils/tokenUtils";
import { Category } from "../utils/consts";
import { EmissionsSourceToCreate } from "../features/emissionsSource/emissionsSourceSlice";
import toast from "react-hot-toast";

export type EmissionsSourceLookup = {
  id: number;
  name: string;
};

export const useEmissionsSourceLookup = (
  emissionsCategoryId: Category,
  emissionsTypeId?: number
) => {
  const fetchEmissionsSources = async () => {
    const url = `${
      env.REACT_APP_API_BASE_URL
    }/emissionsSource/emissionsCategory/${emissionsCategoryId}${
      emissionsTypeId ? `/emissionsType/${emissionsTypeId}` : ""
    }`;
    const response = await fetch(url, {
      headers: { Authorization: getAuthHeader() },
    });
    const emissionsSourceResponse: EmissionsSourceLookup[] = await response.json();

    return emissionsSourceResponse;
  };

  const { data, isLoading } = useQuery(
    `emissionsCategory-${emissionsCategoryId}-emissionsType/${emissionsTypeId}`,
    fetchEmissionsSources
  );

  return { values: data || [], isLoading };
};

export type EmissionsSource = {
  id: number;
  name: string;
  emissionsCategoryId: number;
  emissionsTypeId: number | null;
};

export const useEmissionsSource = () => {
  const fetchEmissionsSources = async () => {
    const url = `${env.REACT_APP_API_BASE_URL}/emissionsSource`;

    const response = await fetch(url, {
      headers: { Authorization: getAuthHeader() },
    });

    const emissionsSourcesResponse = (await response.json()) as EmissionsSource[];
    return emissionsSourcesResponse;
  };

  const { data, isLoading, refetch } = useQuery("emissionsSources", fetchEmissionsSources);

  const createEmissionsSourceMutation = useMutation(
    async (emissionsSource: EmissionsSourceToCreate) => {
      const url = `${env.REACT_APP_API_BASE_URL}/emissionsSource`;
      const response = await fetch(url, {
        method: "POST",
        headers: { Authorization: getAuthHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(emissionsSource),
      });

      return response.ok;
    },
    {
      onSuccess: (response: boolean) => {
        if (response) {
          toast.success(`EmissionsSource created`);
          refetch();
        } else {
          toast.error("EmissionsSource not created");
        }
      },
      onError: () => {
        toast.error("Error");
      },
    }
  );

  const createEmissionsSource = async (emissionsSource: EmissionsSourceToCreate) =>
    await createEmissionsSourceMutation.mutateAsync(emissionsSource);

  const deleteEmissionsSourceMutation = useMutation(
    async (id: number) => {
      const url = `${env.REACT_APP_API_BASE_URL}/emissionsSource/${id}`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: { Authorization: getAuthHeader() },
      });

      return response.ok;
    },
    {
      onSuccess: (response: boolean) => {
        if (response) {
          toast.success(`EmissionsSource deleted`);
          refetch();
        } else {
          toast.error("EmissionsSource not deleted");
        }
      },
      onError: () => {
        toast.error("Error");
      },
    }
  );

  const deleteEmissionsSource = async (id: number) => {
    if (window.confirm("Are you sure you want to delete?"))
      await deleteEmissionsSourceMutation.mutateAsync(id);
  };

  return { values: data, isLoading, createEmissionsSource, deleteEmissionsSource };
};
