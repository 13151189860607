import { useMutation, useQuery } from "react-query";
import { env } from "../env";
import { getAuthHeader } from "../utils/tokenUtils";
import { UnitOfMeasureToCreate } from "../features/unitOfMeasure/unitOfMeasureSlice";
import toast from "react-hot-toast";

export type UnitOfMeasure = {
  id: number;
  name: string;
};

export const useUnitOfMeasureLookup = (emissionsSourceId: number) => {
  const fetchLookupValues = async () => {
    const url = `${env.REACT_APP_API_BASE_URL}/UnitOfMeasure/emissionsSource/${emissionsSourceId}`;
    const response = await fetch(url, {
      headers: { Authorization: getAuthHeader() },
    });
    const unitOfMeasures: UnitOfMeasure[] = await response.json();

    return unitOfMeasures;
  };

  const { data, isLoading } = useQuery(`unitOfMeasure-${emissionsSourceId}`, fetchLookupValues);

  return { values: data || [], isLoading };
};

export const useUnitOfMeasure = () => {
  const fetchUnitOfMeasures = async () => {
    const url = `${env.REACT_APP_API_BASE_URL}/unitOfMeasure`;

    const response = await fetch(url, {
      headers: { Authorization: getAuthHeader() },
    });

    const unitOfMeasuresResponse = (await response.json()) as UnitOfMeasure[];
    return unitOfMeasuresResponse;
  };

  const { data, isLoading, refetch } = useQuery("unitOfMeasures", fetchUnitOfMeasures);

  const createUnitOfMeasureMutation = useMutation(
    async (unitOfMeasure: UnitOfMeasureToCreate) => {
      const url = `${env.REACT_APP_API_BASE_URL}/unitOfMeasure`;
      const response = await fetch(url, {
        method: "POST",
        headers: { Authorization: getAuthHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(unitOfMeasure),
      });

      return response.ok;
    },
    {
      onSuccess: (response: boolean) => {
        if (response) {
          toast.success(`UnitOfMeasure created`);
          refetch();
        } else {
          toast.error("UnitOfMeasure not created");
        }
      },
      onError: () => {
        toast.error("Error");
      },
    }
  );

  const createUnitOfMeasure = async (unitOfMeasure: UnitOfMeasureToCreate) =>
    await createUnitOfMeasureMutation.mutateAsync(unitOfMeasure);

  const deleteUnitOfMeasureMutation = useMutation(
    async (id: number) => {
      const url = `${env.REACT_APP_API_BASE_URL}/unitOfMeasure/${id}`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: { Authorization: getAuthHeader() },
      });

      return response.ok;
    },
    {
      onSuccess: (response: boolean) => {
        if (response) {
          toast.success(`UnitOfMeasure deleted`);
          refetch();
        } else {
          toast.error("UnitOfMeasure not deleted");
        }
      },
      onError: () => {
        toast.error("Error");
      },
    }
  );

  const deleteUnitOfMeasure = async (id: number) => {
    if (window.confirm("Are you sure you want to delete?"))
      await deleteUnitOfMeasureMutation.mutateAsync(id);
  };

  return { values: data, isLoading, createUnitOfMeasure, deleteUnitOfMeasure };
};
