import { useCallback } from "react";
import toast from "react-hot-toast";
import { HttpStatusCode } from "../models/httpStatusCode";
import { env } from "../env";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { GetApiEmissionsEntry, GetApiBasicDetails } from "../models/formTypes";

import {
  transformSavedAssets,
  transformSavedBasicDetails,
  transformSavedEnergyConsumers,
  transformSavedMaterials,
  transformSavedWasteMaterials,
} from "../utils/transformers";
import {
  BasicDetails,
  setBasicDetails,
  setStatus as setBasicDetailsStatus,
} from "../features/basicDetails/basicDetailsSlice";
import {
  Asset,
  setAssets,
  setStatus as setAssetUsageStatus,
} from "../features/assetUsage/assetUsageSlice";
import {
  EnergyConsumer,
  setEnergyConsumers,
  setStatus as setEnergyUsageStatus,
} from "../features/energyUsage/energyUsageSlice";
import {
  Material,
  setMaterials,
  setStatus as setMaterialUsageStatus,
} from "../features/materialUsage/materialUsageSlice";
import { Waste, setWaste, setStatus as setWasteStatus } from "../features/waste/wasteSlice";

import {
  setErrorRestoring,
  setIsRestored,
  setIsRestoring,
} from "../features/formControl/formControlSlice";
import { getAuthHeader } from "../utils/tokenUtils";
import { FormStatus } from "../models/formStatus";
import { useDispatch } from "react-redux";
import { Category } from "../utils/consts";

export const useLoad = () => {
  const dispatch = useDispatch();

  const restoreFormPartial = useCallback(
    async <T, V>(
      url: string,
      transformer: (a: T) => V,
      setAction: ActionCreatorWithPayload<V>,
      setStatusAction: ActionCreatorWithPayload<FormStatus>
    ) => {
      const response = (await fetch(`${env.REACT_APP_API_BASE_URL}/${url}`, {
        headers: {
          Authorization: getAuthHeader(),
        },
      })) as Response;

      const responseBody = (await response.json()) as T;

      const transformed = transformer(responseBody);

      dispatch(setAction(transformed));
      dispatch(setStatusAction(FormStatus.Complete));

      return response.status === HttpStatusCode.Ok || response.status === HttpStatusCode.NotFound;
    },
    [dispatch]
  );

  const restoreForm = useCallback(
    async (workId: string): Promise<void> => {
      const loadingToast = toast.loading("Loading...");
      dispatch(setIsRestoring(true));

      try {
        const partialsLoaded = Promise.all([
          restoreFormPartial<GetApiBasicDetails, BasicDetails>(
            `highleveldetails/${workId}`,
            transformSavedBasicDetails,
            setBasicDetails,
            setBasicDetailsStatus
          ),
          restoreFormPartial<GetApiEmissionsEntry[], Asset[]>(
            `emissionsentry/highleveldetails/${workId}/emissionscategory/${Category.ASSET}`,
            transformSavedAssets,
            setAssets,
            setAssetUsageStatus
          ),
          restoreFormPartial<GetApiEmissionsEntry[], EnergyConsumer[]>(
            `emissionsentry/highleveldetails/${workId}/emissionscategory/${Category.ENERGY}`,
            transformSavedEnergyConsumers,
            setEnergyConsumers,
            setEnergyUsageStatus
          ),
          restoreFormPartial<GetApiEmissionsEntry[], Material[]>(
            `emissionsentry/highleveldetails/${workId}/emissionscategory/${Category.MATERIAL}`,
            transformSavedMaterials,
            setMaterials,
            setMaterialUsageStatus
          ),
          restoreFormPartial<GetApiEmissionsEntry[], Waste[]>(
            `emissionsentry/highleveldetails/${workId}/emissionscategory/${Category.WASTE}`,
            transformSavedWasteMaterials,
            setWaste,
            setWasteStatus
          ),
        ]);

        const success = (await partialsLoaded).every((p) => p);

        if (!success) {
          toast.error(`Failed to load entry with ID ${workId}.`);
          dispatch(setErrorRestoring(true));
        } else {
          dispatch(setIsRestored(true));
        }
      } catch (e) {
        toast.error(`Something went wrong while loading entry with ID ${workId}.`);
        dispatch(setErrorRestoring(true));
      } finally {
        toast.dismiss(loadingToast);
        dispatch(setIsRestoring(false));
      }
    },
    [restoreFormPartial, dispatch]
  );

  return {
    restoreForm,
  };
};
