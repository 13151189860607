import Header from "../Header/Header";
import "./WasteHeaders.scss";

const WasteHeaders = () => {
  return (
    <div className="waste-headers">
      <Header label="Waste material" />
      <Header label="Quantity (tonnes)" />
      <Header label="Units" />
      <Header label="Percentage recycled" />
      <Header label="Scope" />
    </div>
  );
};

export default WasteHeaders;
