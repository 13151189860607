import { useDispatch, useSelector } from "react-redux";
import { Card } from "../Card/Card";
import { Select, TextInput } from "../FormComponents";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import Table, { RowData } from "../Table/Table";
import {
  resetEmissionsSourceFormState,
  setEmissionsSourceName,
  emissionsSourceForm,
  setEmissionsSourceCategory,
  setEmissionsSourceType,
} from "../../features/emissionsSource/emissionsSourceSlice";
import { useEmissionsSource } from "../../hooks/useEmissionsSource";
import { useLookup } from "../../hooks/useLookup";
import { LookupKey } from "../../models/lookup";
import { useEmissionsType } from "../../hooks/useEmissionsType";
import { Category } from "../../utils/consts";

const EmissionsSource = () => {
  const {
    values: emissionsSources,
    createEmissionsSource,
    deleteEmissionsSource,
  } = useEmissionsSource();

  const dispatch = useDispatch();

  const { emissionsSource } = useSelector(emissionsSourceForm);
  const { values: emissionsCategories } = useLookup(LookupKey.Category);
  const { data: emissionsTypes } = useEmissionsType();

  const emissionsSourcesRowData: RowData[] = [];
  emissionsSources?.forEach((emissionsSource) => {
    emissionsSourcesRowData.push({
      ...emissionsSource,
      category:
        emissionsCategories.find((ec) => ec.id === emissionsSource.emissionsCategoryId)?.name ?? "",
      type: emissionsTypes?.find((et) => et.id === emissionsSource.emissionsTypeId)?.name ?? "N/A",
      actions: (
        <div className="actions">
          <PrimaryButton
            aria-label="Delete EmissionsSource"
            label="Delete"
            onClick={() => deleteEmissionsSource(emissionsSource.id)}
          />
        </div>
      ),
    });
  });

  emissionsSourcesRowData.push({
    category: (
      <Select
        id="Category"
        label="Category"
        value={emissionsSource.emissionsCategoryId ?? ""}
        options={emissionsCategories}
        onChange={(e) => dispatch(setEmissionsSourceCategory(Number(e.currentTarget.value)))}
      />
    ),
    type: (
      <Select
        id="Emissions Type"
        label="EmissionsType"
        value={
          emissionsSource.emissionsCategoryId === Category.WASTE
            ? ""
            : emissionsSource.emissionsTypeId ?? ""
        }
        options={emissionsTypes ?? []}
        disabled={emissionsSource.emissionsCategoryId === Category.WASTE}
        onChange={(e) => dispatch(setEmissionsSourceType(Number(e.currentTarget.value)))}
      />
    ),
    name: (
      <TextInput
        id={"emissionsSource-name"}
        label={"Name"}
        value={emissionsSource.name}
        onChange={(e) => dispatch(setEmissionsSourceName(e.currentTarget.value))}
      ></TextInput>
    ),
    actions: (
      <div className="actions">
        <PrimaryButton
          label={"Add Emissions Source"}
          onClick={async () => {
            if (await createEmissionsSource(emissionsSource)) {
              dispatch(resetEmissionsSourceFormState());
            }
          }}
        />
      </div>
    ),
  });

  return (
    <div className="card-layout">
      <Card title="emissions sources">
        <Table
          columns={[
            {
              key: "category",
              header: "Category",
            },
            {
              key: "type",
              header: "Type",
            },
            {
              key: "name",
              header: "Name",
            },
            {
              key: "actions",
              header: "Actions",
            },
          ]}
          data={emissionsSourcesRowData}
        />
      </Card>
    </div>
  );
};

export default EmissionsSource;
