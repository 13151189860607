import { Card } from "../../components/Card/Card";
import { Select, TextInput } from "../../components/FormComponents";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";
import Table, { RowData } from "../../components/Table/Table";
import { useLookup } from "../../hooks/useLookup";
import { useManagedUser } from "../../hooks/useManagedUser";
import { LookupKey } from "../../models/lookup";
import "./Admin.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  managedUserForm,
  resetManagedUserFormState,
  setManagedUserEmail,
  setManagedUserOrganisationId,
  setManagedUserPassword,
  setManagedUserRoleId,
} from "../../features/managedUser/managedUserSlice";

const User = () => {
  const { data: managedUsers, createManagedUser, deleteManagedUser } = useManagedUser();
  const { values: organisationOptions } = useLookup(LookupKey.Organisations);
  const { values: roles } = useLookup(LookupKey.UserRoles);

  const dispatch = useDispatch();

  const { user: managedUser } = useSelector(managedUserForm);

  const usersRowData: RowData[] = [];
  managedUsers?.forEach((user) => {
    usersRowData.push({
      ...user,
      organisation: organisationOptions.find((o) => o.id === user.organisationId)?.name,
      password: "******",
      role: roles.find((r) => r.id === user.roleId)?.name,
      actions: (
        <div className="actions">
          <PrimaryButton
            aria-label="Delete User"
            label="Delete"
            onClick={() => deleteManagedUser(user.email)}
          />
        </div>
      ),
    });
  });

  usersRowData.push({
    email: (
      <TextInput
        id={"user-email"}
        label={"Email"}
        value={managedUser.email}
        onChange={(e) => dispatch(setManagedUserEmail(e.currentTarget.value))}
      />
    ),
    password: (
      <TextInput
        id={"user-password"}
        label={"Password"}
        type="password"
        value={managedUser.password}
        onChange={(e) => dispatch(setManagedUserPassword(e.currentTarget.value))}
      />
    ),
    organisation: (
      <Select
        id="organisation"
        label="Organisation"
        value={managedUser.organisationId}
        options={organisationOptions}
        onChange={(e) => dispatch(setManagedUserOrganisationId(e.currentTarget.value))}
      />
    ),
    role: (
      <Select
        id="Role"
        label="Role"
        value={managedUser.roleId}
        options={roles}
        onChange={(e) => dispatch(setManagedUserRoleId(e.currentTarget.value))}
      />
    ),
    actions: (
      <div className="actions">
        <PrimaryButton
          label={"Add User"}
          onClick={async () => {
            if (await createManagedUser(managedUser)) {
              dispatch(resetManagedUserFormState());
            }
          }}
        />
      </div>
    ),
  });

  return (
    <div className="card-layout">
      <Card title="users">
        <Table
          columns={[
            {
              key: "email",
              header: "Email",
            },
            {
              key: "password",
              header: "Password",
            },
            {
              key: "organisation",
              header: "Organisation",
            },
            {
              key: "role",
              header: "Role",
            },
            {
              key: "actions",
              header: "Actions",
            },
          ]}
          data={usersRowData}
        />
      </Card>
    </div>
  );
};

export default User;
