import { Material } from "../../../features/materialUsage/materialUsageSlice";
import { useEmissionsSourceLookup } from "../../../hooks/useEmissionsSource";
import { useEmissionsTypeLookup } from "../../../hooks/useEmissionsType";
import { useScope } from "../../../hooks/useScope";
import { useUnitOfMeasureLookup } from "../../../hooks/useUnitOfMeasure";
import { Category } from "../../../utils/consts";
import { DeleteButton } from "../../DeleteButton/DeleteButton";
import { NumberInput, Select } from "../../FormComponents";
import "./MaterialRow.scss";

interface MaterialRowProps {
  material: Material;
  onRemove: (a: number | null) => void;
  onChange: (a: Material) => void;
  disabled?: boolean;
}

export const MaterialRow = ({ material, onRemove, onChange, disabled }: MaterialRowProps) => {
  const {
    id,
    materialTypeId,
    materialSubtypeId,
    quantity,
    unitOfMeasureId,
    percentageRecycled,
    scopeId,
  } = material;

  const { values: materialTypes } = useEmissionsTypeLookup(Category.MATERIAL);
  const { values: materialSubtypes } = useEmissionsSourceLookup(
    Category.MATERIAL,
    materialTypeId ? materialTypeId : 1
  );
  const { values: scopes } = useScope();
  const { values: units } = useUnitOfMeasureLookup(materialSubtypeId ?? 0);

  const handleMaterialTypeChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({
      ...material,
      materialTypeId: Number(e.currentTarget.value),
      materialSubtypeId: null,
    });
  };

  const handleMaterialSubtypeChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({
      ...material,
      materialSubtypeId: Number(e.currentTarget.value),
    });
  };

  const handleQuantityChange = (quantity: string) => {
    return onChange({ ...material, quantity: quantity });
  };

  const handlePercentageRecycledChange = (percentageRecycled: string) => {
    return onChange({ ...material, percentageRecycled: percentageRecycled });
  };

  const handleUnitofMeasureChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({ ...material, unitOfMeasureId: Number(e.currentTarget.value) });
  };

  const handleScopeChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({ ...material, scopeId: e.currentTarget.value });
  };

  return (
    <div className="material-row">
      <Select
        id={`material-type-${id}`}
        label="Material Type"
        options={materialTypes}
        value={materialTypeId}
        onChange={handleMaterialTypeChange}
        disabled={disabled}
      />
      <Select
        id={`material-subtype-${id}`}
        label="Material Subtype"
        options={materialSubtypes}
        value={materialSubtypeId}
        onChange={handleMaterialSubtypeChange}
        disabled={disabled}
      />
      <NumberInput
        id={`quantity-${id}`}
        label="Quantity"
        value={quantity}
        min={0}
        onChange={handleQuantityChange}
        disabled={disabled}
      />
      <Select
        id={`units-${id}`}
        label="Units"
        options={units}
        value={unitOfMeasureId}
        onChange={handleUnitofMeasureChange}
        disabled={disabled}
      />
      <NumberInput
        id={`percentage-recycled-${id}`}
        label="Recycled"
        value={percentageRecycled}
        min={0}
        max={100}
        onChange={handlePercentageRecycledChange}
        disabled={disabled}
        percentage={true}
      />
      <Select
        id={`scope-${id}`}
        label="Scope"
        options={scopes}
        value={scopeId}
        onChange={handleScopeChange}
        disabled={disabled}
      />

      <DeleteButton onClick={() => onRemove(id)} disabled={disabled} />
    </div>
  );
};
