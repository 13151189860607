import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { generateLocalId } from "../../utils/dataUtils";
import { FormStatus } from "../../models/formStatus";

export type Waste = {
  id: number;
  wasteMaterialId: number | null;
  quantity: string;
  unitOfMeasureId: number | null;
  percentageRecycled: string;
  scopeId: string | null;
};

export type WasteState = {
  status: FormStatus;
  form: Waste[];
};

const initialState: WasteState = {
  status: FormStatus.NotStarted,
  form: [],
};

export const wasteReducer = {
  setStatus: (state: WasteState, action: PayloadAction<FormStatus>) => {
    state.status = action.payload;
  },
  resetWaste: () => {
    return initialState;
  },
  setWaste: (state: WasteState, action: PayloadAction<Waste[]>) => {
    state.form = action.payload;
  },
  addWaste: (state: WasteState) => {
    const newWaste: Waste = {
      id: generateLocalId(),
      wasteMaterialId: null,
      quantity: "",
      unitOfMeasureId: null,
      percentageRecycled: "",
      scopeId: null,
    };
    state.form.push(newWaste);
  },
  removeWaste: (state: WasteState, action: PayloadAction<Waste>) => {
    const updatedArray = state.form.filter((w) => w.id !== action.payload.id);
    state.form = updatedArray;
  },
  updateWaste: (state: WasteState, action: PayloadAction<Waste>) => {
    const updatedWaste = action.payload;
    const index = state.form.findIndex((a) => a.id === updatedWaste.id);
    state.form[index] = updatedWaste;
  },
};

export const wasteSlice = createSlice({
  name: "waste",
  initialState: initialState,
  reducers: wasteReducer,
});

export const { setStatus, resetWaste, setWaste, addWaste, removeWaste, updateWaste } =
  wasteSlice.actions;

export const selectWaste = (state: RootState) => state.waste;
