import { useNavigate, useParams } from "react-router-dom";
import { Waste, selectWaste, setStatus } from "../features/waste/wasteSlice";
import { useFormControl } from "./useFormControl";
import { useSave } from "./useSave";
import { useCalculate } from "./useCalculate";
import { FormStatus } from "../models/formStatus";
import { useDispatch, useSelector } from "react-redux";

type EmissionsEntryDto = {
  highLevelDetailsId: number;
  emissionsSourceId: number;
  quantity: number;
  unitOfMeasureId: number;
  percentageRecycled: number | undefined;
  scopeId: number;
};

type SaveEmissionsEntryRequest = {
  emissionsEntries: EmissionsEntryDto[];
};

const createSavePayload = (wasteMaterials: Waste[], workId: string): SaveEmissionsEntryRequest => {
  return {
    emissionsEntries: wasteMaterials.map((w) => ({
      highLevelDetailsId: parseInt(workId),
      emissionsSourceId: w.wasteMaterialId || 0,
      quantity: parseInt(w.quantity || ""),
      unitOfMeasureId: w.unitOfMeasureId ?? 0,
      percentageRecycled: parseFloat(w.percentageRecycled || ""),
      scopeId: parseInt(w.scopeId || ""),
    })),
  };
};

export const useWasteEmissionsEntry = () => {
  const waste = useSelector(selectWaste);
  const { closeEntry } = useFormControl();
  const { save, isSaving } = useSave<SaveEmissionsEntryRequest>();
  const navigate = useNavigate();
  const { mode, workId } = useParams();
  const { calculateResults } = useCalculate();
  const dispatch = useDispatch();

  const back = () => {
    navigate(`/${mode}/${workId}/asset-usage`);
  };

  const saveAndClose = async () => {
    if (!workId) return;

    const { success } = await save(
      createSavePayload(waste.form, workId),
      "/emissionsEntry/save/emissionscategory/2"
    );

    await calculateResults(workId);

    if (success) {
      closeEntry();
    }
  };

  const next = async () => {
    if (!workId) return;

    const { success } = await save(
      createSavePayload(waste.form, workId),
      "/emissionsEntry/save/emissionscategory/2"
    );

    await calculateResults(workId);

    if (success) {
      closeEntry();
      dispatch(setStatus(FormStatus.Complete));
      navigate(`/${mode}/${workId}/results`);
    }
  };

  return {
    back,
    saveAndClose,
    next,
    isSaving,
  };
};
