import { useDispatch, useSelector } from "react-redux";
import { FieldGroup } from "../../components/FieldGroup/FieldGroup";
import MaterialHeaders from "../../components/FieldHeaders/MaterialHeaders/MaterialHeaders";
import { MaterialRow } from "../../components/FieldRows/MaterialRow/MaterialRow";
import { FormButtons } from "../../components/FormButtons/FormButtons";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";
import {
  addMaterial,
  removeMaterial,
  selectMaterialUsage,
  updateMaterial,
} from "../../features/materialUsage/materialUsageSlice";
import { useMaterialEmissionsEntry } from "../../hooks/useMaterialEmissionsEntry";

export const MaterialUsage = () => {
  const { form } = useSelector(selectMaterialUsage);
  const { back, saveAndClose, next, isSaving } = useMaterialEmissionsEntry();
  const dispatch = useDispatch();

  const infoPaneText = (
    <>
      Please include the materials purchased for your road/street works in this quarter. <br />
      <br />
      If known, please include what percentage of the materials are from recycled sources. <br />
      <br />
      For Scope, please add whether this is from in-house usage or from contractor usage. If
      unknown, or a combination of the two, please use <i>both</i> <br />
      <br />
      At any point, you can <i>Save and Close</i>. You will be able to return to the same point
      using the Entry Dashboard. <br />
      <br />
      Note, if any significant sources of emissions are missing from this section, please contact
      the team at <a href="mailto: roadtonetzero@eatechnology.com">RTNZ Email</a> <br />
      <br />
    </>
  );

  return (
    <PageContainer
      heading="Material Usage"
      informationalPaneBodyText={infoPaneText}
      displayNavigationPane
    >
      <FieldGroup>
        <MaterialHeaders />
        {form.map((material) => (
          <MaterialRow
            material={material}
            key={material.id}
            onRemove={() => dispatch(removeMaterial(material))}
            onChange={(updatedMaterial) => dispatch(updateMaterial(updatedMaterial))}
            disabled={isSaving}
          />
        ))}
        <PrimaryButton
          onClick={() => dispatch(addMaterial())}
          label="Add New Material"
          disabled={isSaving}
        />
      </FieldGroup>

      <FormButtons onBack={back} onSaveAndClose={saveAndClose} onNext={next} disabled={isSaving} />
    </PageContainer>
  );
};
