import { PageContainer } from "../../components/PageContainer/PageContainer";

import User from "../../components/Admin/User";
import Organisation from "../../components/Admin/Organisation";

const Management = () => {
  return (
    <PageContainer heading="User/Org Management">
      <User />
      <Organisation />
    </PageContainer>
  );
};

export default Management;
