import { useQuery } from "react-query";
import { env } from "../env";
import { getAuthHeader } from "../utils/tokenUtils";

export type Scope = {
  id: number;
  name: string;
};

export const useScope = () => {
  const fetchLookupValues = async () => {
    const url = `${env.REACT_APP_API_BASE_URL}/scope`;
    const response = await fetch(url, {
      headers: { Authorization: getAuthHeader() },
    });
    const scopes: Scope[] = await response.json();

    return scopes;
  };

  const { data, isLoading } = useQuery(`scopes`, fetchLookupValues);

  return { values: data || [], isLoading };
};
