import { useDispatch, useSelector } from "react-redux";
import { FieldGroup } from "../../components/FieldGroup/FieldGroup";
import EnergyConsumerHeaders from "../../components/FieldHeaders/EnergyConsumerHeaders/EnergyConsumerHeaders";
import { EnergyConsumerRow } from "../../components/FieldRows/EnergyConsumerRow/EnergyConsumerRow";
import { FormButtons } from "../../components/FormButtons/FormButtons";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";
import {
  addEnergyConsumer,
  removeEnergyConsumer,
  selectEnergyUsage,
  updateEnergyConsumer,
} from "../../features/energyUsage/energyUsageSlice";
import { useEnergyEmissionsEntry } from "../../hooks/useEnergyEmissionsEntry";

export const EnergyUsage = () => {
  const { form } = useSelector(selectEnergyUsage);
  const { back, saveAndClose, next, isSaving } = useEnergyEmissionsEntry();
  const dispatch = useDispatch();

  const infoPaneText = (
    <>
      Please include all forms of energy (fuel) purchased for your road/street works in this
      quarter. This includes (but is not limited to) energy/fuel used by machinery during works,
      energy/fuel used in transportation of materials, waste and assets, inspections and electricity
      used on-site. <br /> <br />
      If known, please also capture the purpose behind the fuel usage. Otherwise, choose the
      <i>Uncategorised</i> option in the list
      <br /> <br />
      The quantity of the energy is either litres or kWh depending on whether it is Electricity or
      Fuel. Miles will be added in a future release of the tool.
      <br /> <br />
      Given the complexities and variability in emissions from hydrogen production, its consumption
      data is currently recorded in our carbon calculator without contributing to the overall
      emissions result. This approach allows us to monitor hydrogen usage across the sector. In
      future updates, we plan to introduce a refined methodology that will account for emissions
      from hydrogen by considering its various production methods.
      <br /> <br />
      For Scope, please add whether this is from in-house usage or from contractor usage. If
      unknown, or a combination of the two, please use <i>both</i>
      <br /> <br />
      At any point, you can <i>Save and Close</i>. You will be able to return to the same point
      using the Entry Dashboard.
      <br /> <br />
      Note, if any significant sources of emissions are missing from this section, please contact
      the team at <a href="mailto: roadtonetzero@eatechnology.com">RTNZ Email</a>
      <br />
    </>
  );

  return (
    <PageContainer
      heading="Energy Usage"
      informationalPaneBodyText={infoPaneText}
      displayNavigationPane
    >
      <FieldGroup>
        <EnergyConsumerHeaders />
        {form.map((energyConsumer) => (
          <EnergyConsumerRow
            energyConsumer={energyConsumer}
            key={energyConsumer.id}
            onRemove={() => dispatch(removeEnergyConsumer(energyConsumer))}
            onChange={(updatedEnergyConsumer) =>
              dispatch(updateEnergyConsumer(updatedEnergyConsumer))
            }
            disabled={isSaving}
          />
        ))}
        <PrimaryButton
          onClick={() => dispatch(addEnergyConsumer())}
          label="Add New Energy"
          disabled={isSaving}
        />
      </FieldGroup>

      <FormButtons onBack={back} onSaveAndClose={saveAndClose} onNext={next} disabled={isSaving} />
    </PageContainer>
  );
};
