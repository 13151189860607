import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { generateLocalId } from "../../utils/dataUtils";
import { FormStatus } from "../../models/formStatus";

export type EnergyConsumer = {
  id: number;
  energyTypeId: number | null;
  purposeId: number | null;
  quantity: string;
  unitOfMeasureId: number | null;
  scopeId: string | null;
};

export type EnergyUsageState = {
  status: FormStatus;
  form: EnergyConsumer[];
};

const initialState: EnergyUsageState = {
  status: FormStatus.NotStarted,
  form: [],
};

export const energyUsageReducer = {
  setStatus: (state: EnergyUsageState, action: PayloadAction<FormStatus>) => {
    state.status = action.payload;
  },
  resetEnergyUsage: () => {
    return initialState;
  },
  setEnergyConsumers: (state: EnergyUsageState, action: PayloadAction<EnergyConsumer[]>) => {
    state.form = action.payload;
  },
  addEnergyConsumer: (state: EnergyUsageState) => {
    const newEnergyConsumer: EnergyConsumer = {
      id: generateLocalId(),
      energyTypeId: null,
      purposeId: null,
      quantity: "",
      unitOfMeasureId: null,
      scopeId: null,
    };
    state.form.push(newEnergyConsumer);
  },
  removeEnergyConsumer: (state: EnergyUsageState, action: PayloadAction<EnergyConsumer>) => {
    const updatedArray = state.form.filter((e) => e.id !== action.payload.id);
    state.form = updatedArray;
  },
  updateEnergyConsumer: (state: EnergyUsageState, action: PayloadAction<EnergyConsumer>) => {
    const updatedEnergyConsumer = action.payload;
    const index = state.form.findIndex((a) => a.id === updatedEnergyConsumer.id);
    state.form[index] = updatedEnergyConsumer;
  },
};

export const energyUsageSlice = createSlice({
  name: "energyUsage",
  initialState: initialState,
  reducers: energyUsageReducer,
});

export const {
  setStatus,
  resetEnergyUsage,
  setEnergyConsumers,
  addEnergyConsumer,
  removeEnergyConsumer,
  updateEnergyConsumer,
} = energyUsageSlice.actions;

export const selectEnergyUsage = (state: RootState) => state.energyUsage;
