import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

export type EmissionsTypeToCreate = {
  name: string;
};

interface EmissionsTypeState {
  emissionsType: EmissionsTypeToCreate;
}

const initialState: EmissionsTypeState = {
  emissionsType: {
    name: "",
  },
};

export const emissionsTypeSlice = createSlice({
  name: "emissionsType",
  initialState,
  reducers: {
    setEmissionsTypeName: (state, action: PayloadAction<string>) => {
      state.emissionsType.name = action.payload;
    },
    resetEmissionsTypeFormState: (state) => {
      state.emissionsType = initialState.emissionsType;
    },
  },
});

export const { setEmissionsTypeName, resetEmissionsTypeFormState } = emissionsTypeSlice.actions;

export const emissionsTypeForm = (state: RootState) => state.emissionsTypeForm;
