import { useDispatch, useSelector } from "react-redux";
import { Card } from "../Card/Card";
import { TextInput } from "../FormComponents";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import Table, { RowData } from "../Table/Table";
import {
  resetEmissionsTypeFormState,
  setEmissionsTypeName,
  emissionsTypeForm,
} from "../../features/emissionsType/emissionsTypeSlice";
import { useEmissionsType } from "../../hooks/useEmissionsType";

const EmissionsType = () => {
  const { data: emissionsTypes, createEmissionsType, deleteEmissionsType } = useEmissionsType();

  const dispatch = useDispatch();

  const { emissionsType } = useSelector(emissionsTypeForm);

  const emissionsTypesRowData: RowData[] = [];
  emissionsTypes?.forEach((emissionsType) => {
    emissionsTypesRowData.push({
      ...emissionsType,
      actions: (
        <div className="actions">
          <PrimaryButton
            aria-label="Delete EmissionsType"
            label="Delete"
            onClick={() => deleteEmissionsType(emissionsType.id)}
          />
        </div>
      ),
    });
  });

  emissionsTypesRowData.push({
    name: (
      <TextInput
        id={"emissionsType-name"}
        label={"Name"}
        value={emissionsType.name}
        onChange={(e) => dispatch(setEmissionsTypeName(e.currentTarget.value))}
      ></TextInput>
    ),
    actions: (
      <div className="actions">
        <PrimaryButton
          label={"Add Emissions Type"}
          onClick={async () => {
            if (await createEmissionsType(emissionsType)) {
              dispatch(resetEmissionsTypeFormState());
            }
          }}
        />
      </div>
    ),
  });

  return (
    <div className="card-layout">
      <Card title="emissions types">
        <Table
          columns={[
            {
              key: "name",
              header: "Name",
            },
            {
              key: "actions",
              header: "Actions",
            },
          ]}
          data={emissionsTypesRowData}
        />
      </Card>
    </div>
  );
};

export default EmissionsType;
