import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

export type UnitOfMeasureToCreate = {
  name: string;
};

interface UnitOfMeasureState {
  unitOfMeasure: UnitOfMeasureToCreate;
}

const initialState: UnitOfMeasureState = {
  unitOfMeasure: {
    name: "",
  },
};

export const unitOfMeasureSlice = createSlice({
  name: "unitOfMeasure",
  initialState,
  reducers: {
    setUnitOfMeasureName: (state, action: PayloadAction<string>) => {
      state.unitOfMeasure.name = action.payload;
    },
    resetUnitOfMeasureFormState: (state) => {
      state.unitOfMeasure = initialState.unitOfMeasure;
    },
  },
});

export const { setUnitOfMeasureName, resetUnitOfMeasureFormState } = unitOfMeasureSlice.actions;

export const unitOfMeasureForm = (state: RootState) => state.unitOfMeasureForm;
