import { Asset } from "../features/assetUsage/assetUsageSlice";
import { BasicDetails } from "../features/basicDetails/basicDetailsSlice";
import { EnergyConsumer } from "../features/energyUsage/energyUsageSlice";
import { Material } from "../features/materialUsage/materialUsageSlice";
import { Waste } from "../features/waste/wasteSlice";
import { GetApiBasicDetails, GetApiEmissionsEntry } from "../models/formTypes";

export const transformSavedBasicDetails = (apiBasicDetails: GetApiBasicDetails): BasicDetails => {
  return {
    description: apiBasicDetails.description,
    startDate: apiBasicDetails.startDate,
    endDate: apiBasicDetails.endDate,
  };
};

export const transformSavedAssets = (apiAssets: GetApiEmissionsEntry[]): Asset[] => {
  return apiAssets.map((a) => ({
    id: a.id,
    assetTypeId: a.emissionsTypeId,
    assetId: a.emissionsSourceId,
    quantity: a.quantity.toString(),
    unitOfMeasureId: a.unitOfMeasureId,
    percentageRecycled: a.percentageRecycled.toString(),
    scopeId: a.scopeId.toString(),
  }));
};

export const transformSavedEnergyConsumers = (
  apiAssets: GetApiEmissionsEntry[]
): EnergyConsumer[] => {
  return apiAssets.map((a) => ({
    id: a.id,
    energyTypeId: a.emissionsTypeId,
    purposeId: a.emissionsSourceId,
    quantity: a.quantity.toString(),
    unitOfMeasureId: a.unitOfMeasureId,
    scopeId: a.scopeId.toString(),
  }));
};

export const transformSavedMaterials = (apiMaterials: GetApiEmissionsEntry[]): Material[] => {
  return apiMaterials.map((m) => ({
    id: m.id,
    materialTypeId: m.emissionsTypeId,
    materialSubtypeId: m.emissionsSourceId,
    quantity: m.quantity.toString(),
    unitOfMeasureId: m.unitOfMeasureId,
    percentageRecycled: m.percentageRecycled.toString(),
    scopeId: m.scopeId.toString(),
  }));
};

export const transformSavedWasteMaterials = (
  apiWasteMaterials: GetApiEmissionsEntry[]
): Waste[] => {
  return apiWasteMaterials.map((m) => ({
    id: m.id,
    wasteMaterialId: m.emissionsSourceId,
    quantity: m.quantity.toString(),
    unitOfMeasureId: m.unitOfMeasureId,
    percentageRecycled: m.percentageRecycled.toString(),
    scopeId: m.scopeId.toString(),
  }));
};
